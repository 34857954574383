<template>
  <div class="home" v-show="!$route.meta.isloginhead">
    <div>
      <div class="home_nav">
        <div class="home_nav_main">
          <div class="h_left">
            甘肃省房屋市政工程质量安全监督人员网络培训考核系统
            <!-- 甘肃省住房和城乡建设厅全省安全质量监督员培训考核系统 -->
          </div>
          <!-- <div class="h_left">兰州新区燃气从业人员专业培训考核系统</div> -->
          <div class="h_right" v-show="$route.meta.isheadcon">
            <!-- <div
              class="tit"
              v-for="(item, index) in nav_list"
              :class="'noactivecolor'"
              :key="index"
              @click="clicknav(index, item)"
            >
              {{ item.title }}
            </div> -->
            <div
              class="navhome"
              @click="
                clicknav(1, {
                  title: '个人信息',
                  type: 'grxx',
                  path: '/个人信息',
                })
              "
            >
              <!-- <el-avatar icon="el-icon-user-solid"></el-avatar> -->
              <img
                style="
                  border-radius: 50%;
                  height: 50px;
                  width: 50px;
                  overflow: hidden;
                "
                :src="
                  userinfo.stuAvatar
                    ? $imgBaseUrl + userinfo.stuAvatar
                    : require('@/assets/morenimg.png')
                "
                alt="加载失败"
                srcset=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="home_nav_line"></div>
      <!--    <div class="home_main">-->
      <!--      <navmain></navmain>-->
      <!--      <router-view></router-view>-->

      <!--    </div>-->
    </div>
    <!--登录页头部-->
    <div class="login_home" v-show="$route.meta.isloginhead">
      <!-- <img src="../assets/indexlogo.png" alt="" srcset="" /> -->
      <div></div>
      <div class="login_home_right">
        <p>{{ dateFormat(newDate) }}</p>
        <p>欢迎，请登录！</p>
      </div>
    </div>
  </div>
  <!--  v-show="!$route.meta.isloginhead"-->
</template>

<script>
import navmain from '@/components/publicnavmain.vue'
// @ is an alias to /src
export default {
  name: 'HomeView',
  components: {
    navmain,
  },
  data() {
    return {
      userinfo: {},
      newDate: new Date(),
      timer: null,
      currentIndex: '',
      nav_list: [
        // {
        //   path: '/mycourse',
        //   title: '我的课程',
        // },
        {
          path: '/zxxx',
          title: '在线学习',
          type: 'zxxx',
        },
        {
          path: '/zxks',
          title: '在线考试',
          type: 'zxks',
        },
      ],
    }
  },
  mounted() {
    let navavt = localStorage.getItem('navactive')
    if (navavt != '' && navavt != null) {
      this.currentIndex = navavt
    } else {
      this.currentIndex = ''
    }
    //   返回选项卡激活状态
    if (localStorage.getItem('navactive') == '/mycourse') {
      this.activeName = 'first'
    }
    if (localStorage.getItem('navactive') == '/zxzb') {
      this.activeName = 'second'
    }
    if (localStorage.getItem('navactive') == '/zxks') {
      this.activeName = 'third'
    }
    //显示当前日期时间
    let _this = this // 声明一个变量指向Vue实例this，保证作用域一致
    this.timer = setInterval(() => {
      _this.newDate = new Date() // 修改数据date
    }, 1000)
  },
  methods: {
    getInfoList() {
      let userid = localStorage.getItem('userId')
      getInfo(userid).then((res) => {
        if (res.code == 200) {
          this.userinfo = res.data
        }
      })
    },
    clicknav(index, item) {
      this.currentIndex = item.path
      // if (item.title == '我的课程') {
      //   sessionStorage.isshowContent = 'wdkc'
      //   sessionStorage.currentIndex = 'wdkc'
      // } else if (item.title == '在线直播') {
      //   sessionStorage.isshowContent = 'zxzb'
      //   sessionStorage.currentIndex = 'zxzb'
      // } else if (item.title == '在线考试') {
      //   sessionStorage.isshowContent = 'zxks'
      //   sessionStorage.currentIndex = 'zxks'
      // } else if (item.title == '个人信息') {
      //   sessionStorage.isshowContent = 'grxx'
      //   sessionStorage.currentIndex = 'grxx'
      // }
      sessionStorage.active = item.type
      this.$eventBus.$emit('changeFunc', item.type, item.title)
      this.$router.push('/homeview')
      // sessionStorage.showtitle = item.title
      // localStorage.setItem(
      //   'isshowContent',
      //   JSON.stringify({ title: '个人信息', type: 'grxx' })
      // )

      // this.$router.push({ path: '/homeview' })

      // localStorage.setItem('navactive', item.path)

      // 事件总线
    },
    // 事件总线
    sendFunc() {
      this.$eventBus.$emit('changeFunc')
    },
    navhome() {
      console.log(333)
      this.currentIndex = ''
      localStorage.setItem('navactive', '')
      this.showtitle = '个人信息'
      this.isshowContent = 'grxx'
      this.currentIndex = 'grxx'
      this.$router.push('/homeview')
      // 事件总线
      this.sendFunc('grxx')
    },
    // 事件总线
    sendFunc(name) {
      this.$eventBus.$emit('changeFunc', name)
    },
    dateFormat(time) {
      let date = new Date(time)
      let year = date.getFullYear()
      let wk = date.getDay()
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      let month =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      let minutes =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      let seconds =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      let weeks = [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六',
      ]
      let week = weeks[wk]
      // 拼接
      return (
        year +
        '年' +
        month +
        '月' +
        day +
        '日' +
        ' ' +
        hours +
        ':' +
        minutes +
        ':' +
        seconds +
        ' ' +
        week
      )
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer) // 在Vue实例销毁前，清除我们的定时器
      console.log('销毁')
    }
  },
}
</script>
<style lang="scss">
.activecolor {
  color: blue !important;
}

.noactivecolor {
  color: #989797 !important;
}

.home_nav {
  width: 100%;
  height: 60px;
  //background: pink;
  .home_nav_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    height: 100%;
    margin: 0 auto;

    .h_left {
      font-size: 27px;
      font-family: Source Han Serif CN;
      font-weight: bold;
      color: #525252;
    }

    .h_right {
      display: flex;
      align-items: center;

      .tit {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #989797;
        margin-right: 43px;
        cursor: pointer;
      }
    }

    .navhome {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

.home_nav_line {
  width: 100%;
  height: 2px;
  background: linear-gradient(-90deg, #0dccff 0%, #4760ff 100%);
}

.home_main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.login_home {
  width: 1200px;
  height: 43px;
  //background: pink;
  margin: 30px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 310px;
    height: 43px;
  }
  .login_home_right {
    display: flex;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    p {
      margin-left: 50px;
    }
  }
}
</style>
