var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$route.meta.isloginhead),expression:"!$route.meta.isloginhead"}],staticClass:"home"},[_c('div',[_c('div',{staticClass:"home_nav"},[_c('div',{staticClass:"home_nav_main"},[_c('div',{staticClass:"h_left"},[_vm._v(" 甘肃省房屋市政工程质量安全监督人员网络培训考核系统 ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.meta.isheadcon),expression:"$route.meta.isheadcon"}],staticClass:"h_right"},[_c('div',{staticClass:"navhome",on:{"click":function($event){return _vm.clicknav(1, {
                title: '个人信息',
                type: 'grxx',
                path: '/个人信息',
              })}}},[_c('img',{staticStyle:{"border-radius":"50%","height":"50px","width":"50px","overflow":"hidden"},attrs:{"src":_vm.userinfo.stuAvatar
                  ? _vm.$imgBaseUrl + _vm.userinfo.stuAvatar
                  : require('@/assets/morenimg.png'),"alt":"加载失败","srcset":""}})])])])]),_c('div',{staticClass:"home_nav_line"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.meta.isloginhead),expression:"$route.meta.isloginhead"}],staticClass:"login_home"},[_c('div'),_c('div',{staticClass:"login_home_right"},[_c('p',[_vm._v(_vm._s(_vm.dateFormat(_vm.newDate)))]),_c('p',[_vm._v("欢迎，请登录！")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }