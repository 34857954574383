import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/login.vue'
import { getToken } from '@/utils/auth'

Vue.use(VueRouter)

//添加以下代码
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '/',
    component: Login,
    redirect: '/login',
    meta: {
      isheadcon: false,
      isloginhead: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      isheadcon: false,
      isloginhead: true,
    },
  },
  {
    path: '/check',
    name: 'check',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/CheckCertificate.vue'),
    meta: {
      isheadcon: false,
      isloginhead: true,
    },
  },
  {
    path: '/homeview',
    name: 'homeview',
    component: HomeView,
    meta: {
      isheadcon: true,
      isloginhead: false,
    },
  },
  {
    path: '/mycourse',
    name: 'mycourse',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/mycourse.vue'),
    meta: {
      isheadcon: true,
      isloginhead: false,
    },
  },
  {
    path: '/directseeding',
    name: 'directseeding',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/directseeding.vue'),
    meta: {
      isheadcon: true,
      isloginhead: false,
    },
  },
  {
    path: '/coursestudy',
    name: 'coursestudy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/courseStudy.vue'),
    meta: {
      isheadcon: true,
      isloginhead: false,
    },
  },
  {
    path: '/courseplayer',
    name: 'courseplayer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/coursePlayer.vue'),
    meta: {
      isheadcon: true,
      isloginhead: false,
    },
  },
  {
    path: '/live',
    name: 'live',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/live.vue'),
    meta: {
      isheadcon: true,
      isloginhead: false,
      keepAlive: false, // 禁用缓存
    },
  },
  {
    path: '/zxks',
    name: 'zxks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/zxks.vue'),
    meta: {
      isheadcon: true,
      isloginhead: false,
    },
  },
  {
    path: '/zxzb',
    name: 'zxzb',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/zxzb.vue'),
    meta: {
      isheadcon: true,
      isloginhead: false,
    },
  },
  {
    path: '/exam',
    name: 'exam',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/exam.vue'),
    meta: {
      isheadcon: true,
      isloginhead: false,
    },
  },
  {
    path: '/study',
    name: 'study',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Studyclass.vue'),
    meta: {
      isheadcon: true,
      isloginhead: false,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
router.beforeEach((to, from, next) => {
  const token = getToken()

  if (!token && to.path != '/login') {
    next('/login')
  } else {
    next()
  }
})
export default router
