<template>
  <div>
    <div :class="$route.meta.isheadcon == true ? 'footer' : 'footer footer_bg'">
      <div class="footer_main">
        <div class="footer_main_left">
          <img src="../assets/weweima_img.jpg" alt="" srcset="" />
          <div>
            <p>客服热线：0931-2888887</p>
            <p>固定电话：0931-2330999</p>
            <p>合作邮箱：venus19860002@163.com</p>
          </div>
        </div>
        <div class="footer_main_right">
          <p>主办单位: 甘肃省住房和城乡建设厅</p>
          <p>技术提供单位：甘肃建投数字科技有限公司</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'foot',
  watch: {
    // 监听,当路由发生变化的时候执行
    $route: {
      // $route可以用引号，也可以不用引号
      handler(to, from) {
        this.gange()
      },
      deep: true, // 深度观察监听
      // immediate: true, // 第一次初始化渲染就可以监听到
    },
  },

  mounted() {
    this.gange()
  },
  methods: {
    gange() {
      console.log(222, this.$route.path)
      if (this.$route.path == '/login') {
        let dom = document.querySelector('.footer')
        dom.style.position = 'fixed'
        dom.style.bottom = 0
        dom.style.left = 0
      } else {
        let dom = document.querySelector('.footer')
        dom.style.position = ''
      }
    },
  },
}
</script>

<style scoped lang="scss">
.footer_bg {
  background: none !important;
}

.footer {
  width: 100%;
  height: 112px;
  // position: fixed;

  // z-index: 999;
  background: linear-gradient(-90deg, #0dccff 0%, #4760ff 100%);

  .footer_main {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;

    .footer_main_left {
      width: 338px;
      height: 72px;
      display: flex;
      align-content: center;
      justify-content: space-between;

      img {
        width: 72px;
        height: 72px;
      }
      div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.8;
          text-align: left;
        }
        :nth-child(3) {
          margin-bottom: 0;
        }
      }
    }

    .footer_main_right {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 39px;

      p {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
.btn {
  width: 258px;
  height: 43px;
  background: #409eff;
  border-radius: 21px;
}
::v-deep .el-button--medium {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 4px;
  width: 258px;
  height: 43px;
  background: #409eff;
  border-radius: 21px;
  margin: 0 auto;
}
</style>
