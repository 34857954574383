<template>
  <navmain></navmain>
</template>

<script>
import navmain from '@/components/publicnavmain.vue'
import player from '@/components/vueVideoPlaye.vue'
// @ is an alias to /src
export default {
  name: 'HomeView',
  components: {
    navmain,
  },
  data() {
    return {}
  },
  methods: {
    clicknav(index, item) {
      this.currentIndex = index
      this.$router.push(item.path)
    },
  },
}
</script>
<style lang="scss" scoped>
.activecolor {
  color: blue !important;
}

.noactivecolor {
  color: #989797 !important;
}

.home_nav {
  width: 100%;
  height: 60px;
  //background: pink;
  .home_nav_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    height: 100%;
    margin: 0 auto;

    .h_left {
      font-size: 27px;
      font-family: Source Han Serif CN;
      font-weight: bold;
      color: #525252;
    }

    .h_right {
      display: flex;
      align-items: center;

      .tit {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #989797;
        margin-right: 43px;
      }
    }
  }
}

.home_nav_line {
  width: 100%;
  height: 2px;
  background: linear-gradient(-90deg, #0dccff 0%, #4760ff 100%);
}

.home_main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.footer {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  // height: 112px;
  // position: absolute;
  // bottom: 0;
  // left: 0;
  background: linear-gradient(-90deg, #0dccff 0%, #4760ff 100%);

  .footer_main {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .footer_main_left {
      width: 313px;
      height: 54px;
      display: flex;
      align-content: center;
      justify-content: space-between;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .footer_main_right {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 39px;

      p {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
.homes {
  // height: 100vh;
}
</style>
