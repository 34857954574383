import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../rem'
import 'default-passive-events'

Vue.prototype.$imgBaseUrl = process.env.VUE_APP_API_BASE_URL
// console.log('开发地址',process.env.VUE_APP_API_BASE_URL);
import VueAliplayerV2 from "vue-aliplayer-v2";
Vue.filter('format', function(date) {
    var json_date = new Date(date).toJSON();
    return new Date(new Date(json_date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
})
// 注册事件
Vue.prototype.$eventBus = new Vue()
Vue.use(VueAliplayerV2);
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
