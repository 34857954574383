import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
Vue.use(Vuex)

export default new Vuex.Store({

  state: {
  //   课程详情
    courseDetail:{}
  },
  getters: {
  },
  mutations: {
    SET_COUORSE_DETAIL: (state, detail) => {
      state.courseDetail = detail
    },
  },
  actions: {
  },
  modules: {
    user
  },
})
